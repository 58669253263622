import flag from './flag.svg';

export const language = {
    key: 'en',
    name: 'English',
    flag: flag
}

export const translations = {
    home: {
        intro: {
            title: "Let's engage",
            subtitle: "Welcome to the ifunds management portal.",
            content: "This portal can be used to manage the services offered by Ifunds:"
        }
    },
    environment: {
        card: {
            title: "Environment",
            subtitle: "Configure the source environment",
            refresh_config: "Refresh configuration",
            re_authorize: "Re-authorize"
        },
        labels: {
            type: "Type",
            status: "Status",
            unique_name: "Unique name",
            api_url: "Api url",
            authorization: "Authorization"
        },
        status: {
            success: "Active",
            needs_attention: "Needs attention"
        },
        onboarding: {
            error: {
                already_configured: "Environment has already been configured",
                invalid_environment_type: "Product is not supported",
                generic: "Could not create",
            },
            title: "Configure new environment",
            select_environment: "Select environment",
            environment: "Environment",
            product: 'Product',
            products: {
                engage365: 'Engage 365',
                c4np: 'Cloud 4 Nonprofit'
            }
        },
        authorization: {
            authorized: 'Yes',
            unauthorized: 'No'
        }
    },
    buttons: {
        authorize: {
            authorized: "Authorized",
            authorizing: "Authorizing",
            authorize: "Authorize",
            failure: "Authorization failed"
        }
    },
    main: {
        brand: {
            title: "Management portal"
        },
        menu: {
            home: "Home",
            smart_storage: "Smart Storage",
            integrations: "Integrations",
            services: "Services",
            show_less: "Show less",
            show_more: "Show more",
            connect_new_environment: "Connect new environment",
            loading_environments: "Loading environments...",
            logoff: "Sign out",
            logon: "Login",
            attention: "Attention"
        }
    },
    general: {
        go_back: "Go back",
        yes: "Yes",
        no: "No",
        copy: "Copy",
        error: "Error: {{message}}",
        delete: "Delete",
        close: "Close",
        create: "Create",
        subscribed: "Subscribed",
        subscribe: "Subscribe"
    },
    integrations: {
        labels: {
            friendly_name: "Friendly name",
            api_key: "Api key"
        },
        overview: {
            add_integration: "Add integration",
            loading_integrations: "Loading integrations..."
        },
        connector: {
            set_as_default: "Set as default",
            show_details: "View details",
            delete_confirmation: "Do you really want to delete this connection?",
            sync: "Sync",
            sync_confirmation: "Do you really want to sync?"
        },
        capability: {
            'Donation Link': 'Donation Link',
            'Donation': 'Donation',
        },
        marketplace: {
            get_it_now: "Get it now",
            visit_website: "Visit website",
            setup_header: "Setup {{name}}",
            connect: "Connect"
        },
        tikkie: {
            description: "Generate Tikkie donation links for your donors and receive paid tikkies realtime in crm.",
            labels: {
                app_token: 'App token',
                sandbox: 'Sandbox',
                production: 'Production'
            }
        },
        fundiz: {
            description: "Generate fundiz donation links from crm and receive donations realtime in crm.",
            labels: {
                website_key: 'Website key'
            }
        },
        kentaa: {
            description: "Receive donations from Kentaa Forms or Kentaa p2p realtime in crm.",
            details: {
                part1: "In order to retrieve data from kentaa, a webhook needs to be configured.",
                part2: "Please contact kentaa support with a request to configure the webhook url (behind the copy button) for the environment."
            },
            help: {
                apikey: "https://support.kentaa.com/articles/api?locale=en#key%20generation"
            }
        },
        digicollect: {
            description: "Receive donations via DigiCollect collection boxes realtime in crm",
            details: {
                part1: "In order to retrieve data from digicollect, a webhook needs to be configured.",
                part2: "Please contact kentaa support with a request to configure the webhook url (behind the copy button) for the environment."
            },
            help: {
                apikey: "https://api.digicollect.nl/v1/doc#authentication"
            }
        }
    },
    accountholder_parsing: {
        title: "Accountholder",
        description: "Improved recognition of account holder names.",
        checking: "Checking status..."
    },
    features: {
        "Crowdfunding": 'Crowdfunding',
        "Kentaa": 'Kentaa',
        "Connector Sync": 'Connector Sync',
        "Show Pricing": 'Show Pricing',
        "Hosted processing": 'Hosted Processing',
        "Deployments": 'Deployments',
        "Cloud 4 Non-Profit": 'Cloud for Nonprofit',
        "Accountholder Parsing": "Accountholder",
        "Smart Storage": "Smart Storage"
    }
}